<template>
  <div class="collection">
    <!-- <div class="section-wrapper">
      <h3 style="font-size: 20px">收款</h3>
    </div> -->
    <div class="form-wrapper">

        <div class="baseinfo-wrapper">
          <div class="baseinfo-content">
           <img class="payStatus" src="../../../../assets/image/pay_successful2.png" alt="">
           <p class="desc" >报名成功, 请尽快联系学员在线签约</p>
           <p class="tip">提示: 在线签约, 下载海报发送给学员在线签约</p>
          </div>
        </div>

        <!-- <div class="hr"></div> -->
        <div class="paymode-wrapper">
          <h2 class="paymode-title">订单信息</h2>
          <div class="paymode-content">
            <el-row>
              <el-col>
                  <p>
                    <span>订单编号:</span>
                    <span>{{ collectionForm.orderNo }}</span></p>
              </el-col>
              <el-col>
                <p>
                  <span>报名商品:</span>
                  <span>{{collectionForm.goodsName}}<span style="font-size:12px;color:#999">{{collectionForm["showGoodsDetail"] }}</span></span>
                </p>
              </el-col>
              <el-col>
                <p><span>学员姓名:</span><span>{{collectionForm.stuName}}</span></p>
              </el-col>
              <el-col>
                <p>
                  <span>学习帐号:</span>
                  <span>{{collectionForm.stuMoblie}}</span>
                </p>
              </el-col> 
              <el-col>
                <p v-if="collectionForm.waitPay > 0"><span>待收金额:</span><span style="color:#F74954;"> {{ (Math.round(collectionForm.waitPay*100)/100).toFixed(2) }} 元 </span></p>
                <p v-else><span>待收金额:</span><span style="color:#333"> {{ '收齐款' }} </span></p>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- <div class="hr"></div> -->
        <div class="bottom-btn-wrapper">
          <el-button size="middle"  :loading="loading" @click="goDetail">查看订单</el-button>
          <el-button size="middle" type="primary" @click="goOrderManagement" :gap="44">签约</el-button>
        </div>
  
    </div>

    <OnLineSubscribeModal
      ref="mychild"
      :visible="rsModalVisible"
      :createCode="collectionForm"
      :Formmoney="Formmoney"
      :formorder="formorder"
      :modalType="remoteShareModalType"
      :createTime="orderDetails.createTime"
      @onModalClose="onRsModalClose"
      @closeCollection="closeCollection"
      @refreshCollection="refreshCollection"
    />


  </div>
</template>

<script>
import OnLineSubscribeModal from "../components/onlineSubscribeModal.vue"
export default {
  name: "OrderCollection",
  data() {
    return {
      orderId: this.$route.query.orderId,
      collectionForm: {
        // id: "",
        payPrice: "",  //支付价格
        transactionTime: "", //成交日期
        schoolName: "",   //财务组织
        actionUser: "",  //制单人
        payMode: "1",   //收款方式
        payChannel: "alipay", //支付方式
        goodsDetail: '',
        showGoodsDetail: '',
        //学习账号
        stuMoblie: '',
        //商品名称
        goodsName: '',
        waitPay: '',
        //学员姓名
        stuName: '',
        stages: ""
      },
      Formmoney: {},
      formorder: {},
      orderDetails: {},

      payModes: [
        { label: "1", title: "现场扫码" },
        { label: "2", title: "收款海报" },
      ],
      payChannels: [
        { label: "alipay", title: "全款" },
        { label: "wechat", title: "分笔支付" },
      ],
      rsModalVisible: false,
      remoteShareModalType: 'pc',
      loading: false,
      classSrc:"https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=600092344,2146089758&fm=15&gp=0.jpg",
      classTitle:"CPA无忧通过班",
      classSize:"3科目2考期(会计，经济，财务成本)",
      showPayInfo:{
        desc:""
      },
    }
  },
  methods: {

    async getPayDetail(){
      /***
       * orderId，
       * userId
       */
      let params = {
          tradeOrderId: this.$route.query.orderId,
          token: "",
          userId:this.Formmoney.userId || this.collectionForm.userId,
          schoolId:"4",
        }

        const { data } = await this.$fetch("thrid_queryOrderDetail", params)
        let temp = data && data[0] || {};
        //获取商品名称
        this.collectionForm.classImg = temp.originPath || temp.thumbPath;
        this.collectionForm.goodsName = temp.name; 
        this.collectionForm.goodsDetail = temp.specificationNameAll || temp.specificationNameOne || "";
        this.collectionForm.orderNo     = temp.orderNo;
        this.collectionForm.waitPay = temp.waitPayMoney; 
        if( this.collectionForm.goodsDetail ){
          this.collectionForm.showGoodsDetail =   `(${this.collectionForm.goodsDetail})`
          }else {
          this.collectionForm.showGoodsDetail =   ''
        }

       let enrollInfo = temp.enrollInfo || {};
       this.collectionForm.businessId = temp.businessId;
       this.collectionForm.academicLevel = enrollInfo.academicLevel;
       this.collectionForm.major  = enrollInfo.major;
    },


    async getFindOrderDetailsByOrderId(){
        let params = {
          orderId: this.$route.query.orderId,
        }
        let { data } = await this.$fetch("thrid_getOrderDetailInfo", params)
        data = data || {}
        this.usersDetails = data.usersDetails || {};
        this.orderDetails = data.orderDetails || {};
        //获取用户的id
        this.collectionForm.userId = this.usersDetails.userId;
        this.collectionForm.stuName = this.usersDetails.name;
        this.collectionForm.stuMoblie = this.usersDetails.mobile;
        //判断是学历还是财经
        this.collectionForm.businessId = this.orderDetails.businessId;
        this.getPayDetail();
    },


    goDetail() {

       let path = `${this.orderDetails.businessId}` === '1' ?  "/afterSale/cjOrderDetail": "/afterSale/xlOrderDetail";
      //跳转到订单详情、
       this.$router.push( {
        path: path,
        query: {
          orderId: this.collectionForm.orderId,
          status: this.orderDetails.orderStatus
        }
      });
    },

    
    full_url(url)
    {
      let tmpurl = location.protocol +"//"+ location.host;
      return tmpurl+url;
    },


    goOrderManagement() {
  
      //携带姓名，账号
      this.Formmoney.stuName   =  this.collectionForm.stuName;
      this.Formmoney.stuMobile =  this.collectionForm.stuMoblie;
      this.Formmoney.classImg  = this.collectionForm.classImg;
      this.Formmoney.commodityName =  this.collectionForm.goodsName; 
      this.Formmoney.goodsDetail =  this.collectionForm.goodsDetail;
      this.Formmoney.classAndName = this.collectionForm.classAndName
      this.Formmoney.orderId   =  this.$route.query.orderId;
      this.Formmoney.userId    =  this.$route.query.userId || this.collectionForm.userId;
      this.Formmoney.businessId = this.collectionForm.businessId;
      this.Formmoney.academicLevel = this.collectionForm.academicLevel;
      this.Formmoney.major = this.collectionForm.major;
      //等下一个runloop再执行。
      // this.$nextTick(()=>{
        this.$refs.mychild.crateQrcode()
        this.rsModalVisible = true;
      // })
      
    },
    // 远程分享弹窗关闭回调
    onRsModalClose() {
      this.rsModalVisible = false
    },
    // 结束收款
    closeCollection() {
      this.rsModalVisible = false
    },
    // 刷新收款码
    refreshCollection() {
      this.onSubmit(this.collectionForm)
    }
  },
  components: {
    // remoteShareModal,
    OnLineSubscribeModal
  },
  computed: {
    showTips() {
      return this.collectionForm.payMode == 2
    },
    // userInfo() {
    //   return this.$store.state.userInfo
    // },
  },
  mounted(){
    /***
     * 1、订单ID
     * 2、userID
     */
    if (this.$route.query && this.$route.query.orderId) {

      ////下面代码除了orderId，其他参数已经改成了通过 getFindOrderDetailsByOrderId、getPayDetail从后台获取
      this.collectionForm.orderId = this.$route.query.orderId
      this.collectionForm.userId  = this.$route.query.userId;
      this.collectionForm.orderNo = this.$route.query.orderNo
      //获取报名商品
      this.collectionForm.goodsName = this.$route.query.name;
      this.collectionForm.goodsDetail = this.$route.query.goodsDetail;
      this.collectionForm.classAndName = `${this.collectionForm.goodsName}(${this.collectionForm.goodsDetail})` 
      this.Formmoney.goodsDetail = this.collectionForm.goodsDetail; 
      //获取学员姓名
      this.collectionForm.stuMoblie = this.$route.query.stuMoblie;
      //获取学院账号
      this.collectionForm.stuName   = this.$route.query.stuName;
      this.formCollection = this.$route.query.receiveType === "ture" ?true:false //url里boolean被转成了字符串
    }

    // this.getPayDetail();
    this.getFindOrderDetailsByOrderId();
  }
}
</script>

<style lang="scss" scoped>
// @import "@/assets/css/variable.scss";
.collection {
  width: 94%;
  margin: 0 auto;
  padding-top: 20px;
  // padding-bottom: 36px;
  background-color: #fff;
  .breadcrumb-wrapper {
    padding-left: 18px;
    margin-bottom: 18px;
  }
  .section-wrapper {
    margin-bottom: 24px;
  }
  .baseinfo-wrapper {
    margin-top: 200px;
    .baseinfo-title {
      padding-left: 30px;
      margin-bottom: 18px;
      font-size: 14px;
      font-weight: 700;
      color: #666;
    }
    .baseinfo-content {
      padding-left: 20px;
      .desc{
        margin-bottom: 20px;
        font-size: 18px;
        color: #182D42;
      }
      .tip {
        height: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #337AFF;
      }
      .payStatus{
        width: 140px;
        height: 140px;
        object-fit: contain;
      }
      text-align: center;
    }
  }
  .paymode-wrapper {
      width: 1508px;
      height: 150px;
      margin:50px auto 0;
      border-radius: 10px;
    .paymode-title {
      font-size: 16px;
      color: #3A3C42;
      font-weight: bold;
    }
    .paymode-content {
      overflow:hidden;
      .el-row {
        .el-col {
          min-width: 20%;
          width: auto !important;;
          float:left;
          padding-top: 10px;
          padding-left: 8px;
          p {
            margin-bottom:0;
            span {
              display:block;
              font-size: 14px;
              color:#333333;
            }
            span:first-child {
              margin-bottom: 8px;
              color: #999999;
              margin-right: 12px;
            }
          }
          
          .payWay-radio:first-child {
            margin-right: 54px;
          }
        }
      }
      
    }
  }
  .bottom-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // padding-top: 10px;
    margin: 80px 0;
    .el-button {
      width: 120px;
      height: 42px;
      border-radius: 0;
    }
  }
}
.hr {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  padding: 0 18px;
  border: 1px dashed #e1e4eb;
}

.tip-enter,
.tip-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.tip-enter-active,
.tip-leave-active {
  transition: 0.3s;
}
</style>
