var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collection" },
    [
      _c("div", { staticClass: "form-wrapper" }, [
        _vm._m(0),
        _c("div", { staticClass: "paymode-wrapper" }, [
          _c("h2", { staticClass: "paymode-title" }, [_vm._v("订单信息")]),
          _c(
            "div",
            { staticClass: "paymode-content" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", [
                    _c("p", [
                      _c("span", [_vm._v("订单编号:")]),
                      _c("span", [_vm._v(_vm._s(_vm.collectionForm.orderNo))])
                    ])
                  ]),
                  _c("el-col", [
                    _c("p", [
                      _c("span", [_vm._v("报名商品:")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.collectionForm.goodsName)),
                        _c(
                          "span",
                          {
                            staticStyle: { "font-size": "12px", color: "#999" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.collectionForm["showGoodsDetail"])
                            )
                          ]
                        )
                      ])
                    ])
                  ]),
                  _c("el-col", [
                    _c("p", [
                      _c("span", [_vm._v("学员姓名:")]),
                      _c("span", [_vm._v(_vm._s(_vm.collectionForm.stuName))])
                    ])
                  ]),
                  _c("el-col", [
                    _c("p", [
                      _c("span", [_vm._v("学习帐号:")]),
                      _c("span", [_vm._v(_vm._s(_vm.collectionForm.stuMoblie))])
                    ])
                  ]),
                  _c("el-col", [
                    _vm.collectionForm.waitPay > 0
                      ? _c("p", [
                          _c("span", [_vm._v("待收金额:")]),
                          _c("span", { staticStyle: { color: "#F74954" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    Math.round(
                                      _vm.collectionForm.waitPay * 100
                                    ) / 100
                                  ).toFixed(2)
                                ) +
                                " 元 "
                            )
                          ])
                        ])
                      : _c("p", [
                          _c("span", [_vm._v("待收金额:")]),
                          _c("span", { staticStyle: { color: "#333" } }, [
                            _vm._v(" " + _vm._s("收齐款") + " ")
                          ])
                        ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "bottom-btn-wrapper" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "middle", loading: _vm.loading },
                on: { click: _vm.goDetail }
              },
              [_vm._v("查看订单")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "middle", type: "primary", gap: 44 },
                on: { click: _vm.goOrderManagement }
              },
              [_vm._v("签约")]
            )
          ],
          1
        )
      ]),
      _c("OnLineSubscribeModal", {
        ref: "mychild",
        attrs: {
          visible: _vm.rsModalVisible,
          createCode: _vm.collectionForm,
          Formmoney: _vm.Formmoney,
          formorder: _vm.formorder,
          modalType: _vm.remoteShareModalType,
          createTime: _vm.orderDetails.createTime
        },
        on: {
          onModalClose: _vm.onRsModalClose,
          closeCollection: _vm.closeCollection,
          refreshCollection: _vm.refreshCollection
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "baseinfo-wrapper" }, [
      _c("div", { staticClass: "baseinfo-content" }, [
        _c("img", {
          staticClass: "payStatus",
          attrs: {
            src: require("../../../../assets/image/pay_successful2.png"),
            alt: ""
          }
        }),
        _c("p", { staticClass: "desc" }, [
          _vm._v("报名成功, 请尽快联系学员在线签约")
        ]),
        _c("p", { staticClass: "tip" }, [
          _vm._v("提示: 在线签约, 下载海报发送给学员在线签约")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }